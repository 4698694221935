.indicator {
  position: relative;
}

/* Increase clickable area for indicator */
.indicator::before {
  position: absolute;
  inset-inline-start: 0;
  inset-inline-end: 0;
  top: -10px;
  bottom: -10px;
  z-index: 2;
  content: '';
}

.skeleton_card {
  animation: pulse 1.5s infinite;
}

.skeleton_part {
  --current-line-count: 1em;
  --default-line-height: var(--v-font-16-lineheight);
  min-height: calc(
    var(--current-line-count) *
      var(--current-line-height, var(--default-line-height))
  );
  background-color: inherit;
  filter: brightness(80%);
  animation: pulse 1.5s infinite;
}
@media (--v-until-lg) {
  .filter__gradient_right {
    --mask-right: linear-gradient(
        to right,
        var(--v-color-always-black) 0,
        var(--v-color-always-black) 75%,
        var(--v-color-ornament-primary) 95%,
        var(--v-color-ornament-primary) 0
      )
      100% 50% / 100% 100% repeat-x;
    mask: var(--mask-right);
  }
  .filter__gradient_left {
    --mask-left: linear-gradient(
        to left,
        var(--v-color-always-black) 0,
        var(--v-color-always-black) 75%,
        var(--v-color-ornament-primary) 95%,
        var(--v-color-ornament-primary) 0
      )
      100% 50% / 100% 100% repeat-x;
    mask: var(--mask-left);
  }
}
@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
}

.carousel.bleed {
  position: relative;
  width: calc(100vw - var(--scrollbar-width, 0px));
  padding-inline: var(--full-width-padding, 0px);
  inset-inline-start: 50%;
  transform: translateX(-50%);
  scroll-padding: var(--full-width-padding, 0px);
}
.carousel.bleed.left {
  clip-path: inset(0 var(--full-width-padding, 0px) 0 0);
}
.carousel.bleed.right {
  clip-path: inset(0 0 0 var(--full-width-padding, 0px));
}

[dir='rtl'] .carousel.bleed {
  inset-inline-end: 50%;
  transform: translateX(50%);
}

[dir='rtl'] .carousel.bleed.left {
  clip-path: inset(0 0 0 var(--full-width-padding, 0px));
}

[dir='rtl'] .carousel.bleed.right {
  clip-path: inset(0 var(--full-width-padding, 0px) 0 0);
}
