.thumbnail {
  filter: blur(5px);
  background-repeat: no-repeat;
  background-size: cover;
}

.thumbnail.loaded {
  filter: blur(0);
  background-size: 0;
}

.thumbnail picture {
  opacity: 0;
}

.thumbnail.loaded picture {
  opacity: 1;
  transition: opacity 0.3s ease-out;
}
